import '../plugins/mobileNav';
import '../utils/responsiveHelper';

/* eslint-disable */

// mobile menu init
export default function initMobileNav () {
  ResponsiveHelper.addRange({
    '..1024': {
      on: function () {
        jQuery('body').mobileNav({
          menuActiveClass: 'nav-active',
          menuOpener: '.js-nav-opener',
          hideOnClickOutside: false,
          toggleEvent: 'click'
        });
      },
      off: function () {
        jQuery('body').mobileNav('destroy');
      }
    }
  });

  jQuery('.menu-item a').on('click', function (e) {
    e.preventDefault();

    jQuery('body').removeClass('nav-active');

    let target = jQuery(this).attr('href');
    let hashIndex = target.indexOf('#');

    if (hashIndex !== -1 && jQuery(target.substring(hashIndex)).length > 0) {
      let anchor = target.substring(hashIndex);
      let headerHeight = jQuery('header').outerHeight();
      let targetOffset = jQuery(anchor).offset().top - headerHeight;
      jQuery('html, body').animate({
        scrollTop: targetOffset
      }, 1000);
    } else {
      window.location.href = target;
    }
  });

}
