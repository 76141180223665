/* eslint-disable */

let key = document.querySelector('[data-google-key]')
const API_KEY = 'AIzaSyCEDF7P_NRy_a09UtAFFnPyqbJzShzhAZo';
let data_id = document.querySelector('[data-google-id]')
let map_id = '4504f8b37365c3d0'
if (data_id) {
  map_id = data_id.getAttribute("data-google-id")
}
const HOLDER_SELECTOR = '.map-block'
let holders = []

async function init () {
  const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

  holders.forEach((holder) => {
    const markers = holder.getAttribute("data-markers");
    const centerAttrVal = holder.getAttribute("data-center");
    const center = centerAttrVal ? JSON.parse(centerAttrVal) : null;
    const defaultCenter = center ? { lat: center[0], lng: center[1] } : { lat: 0, lng: 0 };
    const zoom = parseInt(holder.getAttribute("data-zoom")) || 14;

    if (markers) {
      const markersArray = JSON.parse(markers);
      const markersInst = [];
      const map = new google.maps.Map(holder, {
        center: defaultCenter,
        disableDefaultUI: true,
        zoomControl: true,
        zoom: zoom,
        mapId: map_id
      });
      markersArray.forEach((marker) => {
        const coords = marker.position?.split(',');
        const position = { lat: Number(coords[0]), lng: Number(coords[1]) };
        const mapIcon = document.createElement('img');
        mapIcon.src = marker.icon;
        mapIcon.alt = 'marker';
        mapIcon.setAttribute('class', marker.class);
        markersInst.push(new google.maps.marker.AdvancedMarkerElement({
          map,
          position: position,
          content: mapIcon
        }));
      });

      google.maps.event.addListenerOnce(map, 'bounds_changed', function () {
        const zoomLevel = parseInt(holder.getAttribute("data-zoom")) || 12;
        map.setZoom(zoomLevel);
      });

      // set center according to markers
      const bounds = new google.maps.LatLngBounds();
      markersInst.forEach((marker) => {
        bounds.extend(marker.position);
      });
      map.fitBounds(bounds);
    }
  })
}

function setGoogleMapAPI () {
  window.initMap = init

  const tag = document.createElement('script');
  tag.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&loading=async&callback=initMap`;
  tag.setAttribute('async', 'true');

  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

export default function initGoogleMap () {
  holders = Array.prototype.slice.call(document.querySelectorAll(HOLDER_SELECTOR))

  if (holders.length) setGoogleMapAPI()
}
