export default function initFixedPosition () {
  var lastScrollTop = 0;

  function toggleClass () {
    var st = jQuery(window).scrollTop();
    var header = jQuery('.header');

    header.toggleClass('fixed-position', (st !== 0));

    lastScrollTop = st;
  }

  jQuery(window).on('scroll', toggleClass);
}