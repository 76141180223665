export default function toggleVideoPopup () {
  function openPopup () {
    jQuery('body').addClass('popup-video-opened');
  }

  function closePopup () {
    jQuery('body').removeClass('popup-video-opened');
  }

  function playFirstVideo () {
    const $firstVideo = jQuery('.hero-section .video-wrapper video');
    $firstVideo.get(0).play();
  }

  function movePlayButton (e) {
    const $playButton = jQuery('.js-popup-video-play');
    const $section = $playButton.closest('.hero-section');
    const $header = jQuery('.header');
    const $minTopPosition = $header.outerHeight() + 70;
    const scrollPosition = jQuery(window).scrollTop();

    if (jQuery(window).width() >= 1024) {
      let sectionTop = $section.offset().top;
      let sectionBottom = sectionTop + $section.outerHeight();
      let isInView = e.clientY + scrollPosition >= sectionTop && e.clientY + scrollPosition <= sectionBottom;

      if (isInView) {
        $playButton.addClass('visible');
        let topPosition = e.clientY + scrollPosition;
        if (topPosition < $minTopPosition) {
          topPosition = $minTopPosition;
        }
        $playButton.css({
          top: topPosition,
          left: e.clientX
        });
      } else {
        $playButton.removeClass('visible');
      }
    } else {
      $playButton.removeClass('visible');
    }
  }

  playFirstVideo();

  jQuery('.js-popup-video-play').on('click', function (e) {
    const $popup = jQuery(this).closest('.hero-section').find('.video-popup-overlay');
    const $popupVideo = $popup.find('video');

    jQuery('video').each(function () {
      this.pause();
    });

    $popupVideo.get(0).play();

    openPopup();
  });

  jQuery('.close-popup').on('click', function () {
    const $popup = jQuery(this).closest('.video-popup-overlay');
    const $popupVideo = $popup.find('video');

    $popupVideo.get(0).pause();

    closePopup();

    playFirstVideo();
  });

  jQuery('.video-popup-overlay').on('click', function (e) {
    if (e.target === this) {
      const $popup = jQuery(this);
      closePopup();
      playFirstVideo();
    }
  });

  jQuery(window).on('mousemove', movePlayButton);

  jQuery(window).on('resize', function () {
    jQuery(window).off('mousemove', movePlayButton);
    jQuery(window).on('mousemove', movePlayButton);
  });
}

