import '../styles/style.scss'
import './utils/polyfills'
import './utils/detectTouch'
import './utils/responsiveHelper'
import './utils/vh';
import ResizeHandler from './utils/resize-active'
import initMobileNav from './components/mobileNav'
import initGoogleMap from './components/initGoogleMap'
import initFixedPosition from './components/initFixedPosition'
import toggleInteractivePins from './components/toggleInteractivePins'
import toggleVideoPopup from './components/toggleVideoPopup'

import 'intersection-observer' // Polyfill IntersectionObserver API for IE and old Safari
import LazyLoad from 'vanilla-lazyload' // Lazy load images plugin

const resizeHandler = new ResizeHandler()

/** Load Events */
window.addEventListener('DOMContentLoaded', () => {
  /**
   * Init lazyload and polyfill
   */
  const lazyLoadInsance = new LazyLoad()
  lazyLoadInsance.update()
  resizeHandler.init()
  initMobileNav()
  initGoogleMap()
  initFixedPosition()
  toggleInteractivePins()
  toggleVideoPopup()
})
